export const PRODUCTS_PER_FETCH = 30;

// IMPORTANT: Do not change the order of this array
export const CATEGORIES = [
  "PainNormal",
  "Pain",
  "Gateaux",
  "Sec",
  "Viennoiserie",
  "Sale",
  "Oriental",
  "Boisson",
  "Dish",
  "Divers", // Add this new category
] as const;

export type CategoryName = (typeof CATEGORIES)[number];

export const CATEGORIES_IN_DB = CATEGORIES.map((_, index) => index);

// IMPORTANT: Do not change the order of this array
// This array is compatible with INGREDIENT_UNITS array
export const PRODUCT_UNITS = ["kg", "L", "ps"] as const;

export type ProductUnit = (typeof PRODUCT_UNITS)[number];
